// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-mailing-list-js": () => import("/opt/build/repo/src/pages/MailingList.js" /* webpackChunkName: "component---src-pages-mailing-list-js" */),
  "component---src-pages-test-js": () => import("/opt/build/repo/src/pages/Test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-about-js": () => import("/opt/build/repo/src/templates/About.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-issue-js": () => import("/opt/build/repo/src/templates/Issue.js" /* webpackChunkName: "component---src-templates-issue-js" */),
  "component---src-templates-contact-js": () => import("/opt/build/repo/src/templates/Contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-article-js": () => import("/opt/build/repo/src/templates/Article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-issues-js": () => import("/opt/build/repo/src/pages/issues.js" /* webpackChunkName: "component---src-pages-issues-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

